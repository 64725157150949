@keyframes heartbeat {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 0.25;
    transform: scale(1);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.25;
    transform: scale(0.975);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 0.5;
    transform: scale(0.975);
  }
  90% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.heartbeat-animation {
    animation: heartbeat 4000ms infinite ease-in-out;
  }