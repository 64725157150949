body {
  background-color: white;
  color: #212;
}
body a {
  color: #212;
}

/* Hide eye icon in password inputs for Edge */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}
